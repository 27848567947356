import { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Name from "../components/Name";
import useMediaQuery from "../hooks/useMediaQuery";

const Link = ({ page, selectedPage, setSelectedPage, setIsMenuToggled }) => {
  const lowerCasePage = page.toLowerCase();
  return (
    <AnchorLink
      className={`${
        selectedPage === lowerCasePage ? "text-yellow" : ""
      } hover:text-yellow   transation duration-500`}
      href={`#${lowerCasePage}`}
      onClick={() => {
        setSelectedPage(lowerCasePage);
        if (typeof setIsMenuToggled === "function") {
          setIsMenuToggled(false);
        }
      }}
    >
      {page}
    </AnchorLink>
  );
};
const Navbar = ({ isTopOfPage, selectedPage, setSelectedPage }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isAboveSmallscreens = useMediaQuery("(min-width: 768px)");
  const navbarBg = isTopOfPage
    ? "bg-gradient-to-b from-opaque-black to-transparent "
    : "bg-gradient-to-b from-black to-transparent ";
  return (
    <nav className={`${navbarBg} z-40 w-full fixed top-0 py-6  text-white `}>
      <div className="flex items-center justify-between mx-auto w-5/6">
        <div onClick={scrollToTop} className="cursor-pointer">
          <Name />
        </div>
        {isAboveSmallscreens ? (
          <div className="flex justify-between gap-8 md:gap-16 font-opensans md:text-base  font-semibold">
            <Link
              page="HOME"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              page="ABOUT"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              page="AWARDS"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              page="GALLERY"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />

            <Link
              page="CONTACT"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </div>
        ) : (
          <div className="relative">
            {isMenuToggled ? null : (
              <button
                type="button"
                className="rounded-full bg-yellow p-2"
                onClick={() => setIsMenuToggled(!isMenuToggled)}
              >
                <img alt="menu-icon" src="../assets/menu-icon.svg" />
              </button>
            )}
            {isMenuToggled && (
              <div className="fixed right-0 bottom-0 h-full bg-opaque-black  w-[150px]">
                <div className="flex justify-end p-12">
                  <button
                    type="button"
                    onClick={() => setIsMenuToggled(!isMenuToggled)}
                  >
                    <img alt="close-icon" src="../assets/close-icon.svg" />
                  </button>
                </div>

                <div className="flex flex-col gap-6 text-2xl text-center text-white">
                  <Link
                    page="HOME"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    setIsMenuToggled={setIsMenuToggled}
                  />
                  <Link
                    page="ABOUT"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    setIsMenuToggled={setIsMenuToggled}
                  />
                  <Link
                    page="AWARDS"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    setIsMenuToggled={setIsMenuToggled}
                  />
                  <Link
                    page="GALLERY"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    setIsMenuToggled={setIsMenuToggled}
                  />
                  <Link
                    page="CONTACT"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    setIsMenuToggled={setIsMenuToggled}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
