import { BarLoader } from "react-spinners";
const Loading = () => {
  return (
    <div className=" absolute inset-0 flex justify-center items-center   ">
      <BarLoader color={"#ffffff"} loading={true} />
    </div>
  );
};

export default Loading;
