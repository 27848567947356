import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { Fa500Px } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaBehance } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
const Contact = () => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();
  const onSubmit = async (e) => {
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };
  return (
    <section
      id="contact"
      className=" w-5/6 mx-auto mt-10 md:flex md:items-center md:h-full"
    >
      <div className="w-full">
        <motion.div
          className="text-xl text-yellow text-center md:text-left"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.1, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          CONTACT
        </motion.div>

        <motion.div
          className="h-0.5 bg-gradient-rainbow my-8"
          whileInView="visible"
          variants={{
            hidden: { width: 0 },
            visible: { width: "100%" },
          }}
          viewport={{ once: true, amount: 0.5 }}
          initial="hidden"
          transition={{ duration: 0.5 }}
        />

        <div className="md:flex justify-evenly ">
          <motion.div
            className="w-full"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <form
              className="flex flex-col m-auto md:mr-10 mb-8"
              target="_blank"
              onSubmit={onSubmit}
              action="https://formsubmit.co/hayhamlt@gmail.com"
              method="POST"
            >
              <input
                className="   p-3 bg-black border-2 placeholder-opaque-white mb-2"
                type="text "
                placeholder="NAME"
                {...register("name", { required: true, maxLength: 100 })}
              />
              {errors.name && (
                <p className="text-red">
                  {errors.name.type === "required" && "This field is required."}
                  {errors.name.type === "maxLength" && "MaxLength is 100 char."}
                </p>
              )}

              <input
                className="p-3 bg-black border-2 placeholder-opaque-white  mb-2"
                type="text"
                placeholder="EMAIL"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email && (
                <p className="text-red">
                  {errors.email.type === "required" &&
                    "This field is required."}
                  {errors.email.type === "pattern" && "Invalid email address."}
                </p>
              )}

              <textarea
                className="bg-black border-2 placeholder-opaque-white p-3 text-white  mb-2"
                type="text"
                rows="4"
                cols="50"
                placeholder="MESSAGE"
                {...register("messageme", { required: true, maxLength: 600 })}
              />
              {errors.email && (
                <p className="text-red">
                  {errors.messageme.type === "required" &&
                    "This field is required."}
                  {errors.messageme.type === "maxLength" &&
                    "MaxLength is 600 char."}
                </p>
              )}
              <button
                type="submit"
                className="bg-yellow font-semibold p-5 text-deep-blue hover:bg-[#701a75] hover:text-white transition duration-500"
              >
                SEND ME A MESSAGE
              </button>
            </form>
          </motion.div>
          <motion.div
            className="w-full flex md:justify-start justify-evenly "
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <a
              href=" https://500px.com/p/hamlt?view=photos"
              target="_blanket"
              className="mr-4"
            >
              <Fa500Px className=" w-10 h-10 hover:text-yellow mb-8 cursor-pointer" />
            </a>
            <a
              href="https://www.instagram.com/hayhamlt/"
              target="_blanket"
              className="mr-4"
            >
              <FaInstagram className=" w-10 h-10  hover:text-yellow cursor-pointer" />
            </a>

            <a
              className="mr-4"
              href="https://www.behance.net/hamLT?fbclid=IwAR38-RgGgTbnICrJmjD6rtJjZCmfuCCOzTC1NKSCDsqrhSYbEM8BLE_Zse8"
              target="_blanket"
            >
              <FaBehance className=" w-10 h-10  hover:text-yellow cursor-pointer" />
            </a>

            <a
              className="mr-4"
              href="https://www.linkedin.com/in/hamlt/?fbclid=IwAR0NUTVEqx3ChweRZyJMew9b5YTqCxqIkfDTpy6shxfwXxDYHjQwQvtvFTk"
              target="_blanket"
            >
              <FaLinkedin className=" w-10 h-10  hover:text-yellow cursor-pointer" />
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
