import { Outlet } from "react-router-dom";
import GalleryMenu from "./GalleryMenu";
import NavbarGallery from "./NavbarGallery";
import ScrollButton from "../components/ScrollButton";

const PhotoGallery = () => {
  return (
    <>
      <NavbarGallery />
      <div className="relative top-28 w-5/6 mx-auto">
        <GalleryMenu />
        <div>
          <Outlet />
        </div>
      </div>
      <ScrollButton />
    </>
  );
};

export default PhotoGallery;
