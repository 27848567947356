import About from "./About";
import Contact from "./Contact";
import Photos from "./Photos";
import ImageCarousel from "./ImageCarousel";
import Awards from "./Awards";
import Navbar from "./Navbar";
import { useEffect, useState } from "react";
import ScrollButton from "../components/ScrollButton";

const Landing = () => {
  const [selectedPage, setSelectedPage] = useState("home");
  const [isTopOfPage, setIsTopOfPage] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
        setSelectedPage("home");
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <Navbar
        isTopOfPage={isTopOfPage}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
      <ImageCarousel />
      <About />
      <Awards />
      <Photos />
      <Contact />
      <ScrollButton />

      <p className="text-center pb-6 m-auto">©2023 www.hamlt.com</p>
    </>
  );
};

export default Landing;
