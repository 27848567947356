const Name = () => {
  return (
    <h4 className=" font-qwitcher text-4xl text-center">
      <div>hamlet hayrapetyan</div>
      <div className="font-opensans font-thin  m-[-12px]  text-[10px] uppercase ">
        photography
      </div>
    </h4>
  );
};
export default Name;
