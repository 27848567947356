import { NavLink, useLocation } from "react-router-dom";

const GalleryMenu = () => {
  const galleryLink = [
    {
      title: "Urban",
      link: "/photo-gallery/urban",
    },
    {
      title: "People",
      link: "/photo-gallery/people",
    },
    {
      title: "Nature",
      link: "/photo-gallery/nature",
    },
  ];

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <nav className="flex justify-center mx-auto mb-10">
      {galleryLink.map((item) => {
        return (
          <NavLink
            key={item.title}
            className={` px-4 py-2 md:mx-4 uppercase mx-2  ${
              isActive(`${item.link}`)
                ? "  text-yellow"
                : "bg-red-500 text-white"
            }   hover:text-yellow  transation duration-500`}
            to={item.link}
          >
            <p>{item.title}</p>
          </NavLink>
        );
      })}
    </nav>
  );
};

export default GalleryMenu;
