import { Link } from "react-router-dom";
import Name from "../components/Name";

const NavbarGallery = () => {
  return (
    <nav className="bg-gradient-to-b from-black to-transparent  z-40 w-full fixed top-0 py-6  text-white">
      <div className="flex items-center justify-between mx-auto w-5/6">
        <Link to="/">
          <Name />
        </Link>

        <div className="flex justify-between gap-8 md:gap-16 font-opensans text-base font-semibold hover:text-yellow  transation duration-500">
          <Link to="/">HOME</Link>
        </div>
      </div>
    </nav>
  );
};

export default NavbarGallery;
