import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="pt-28 container mx-auto mt-10 text-center md:h-full">
      <h1 className=" text-6xl mb-10">Error 404</h1>
      <div className="mb-20 text-lg">
        We can`t find the page you`re looking for
      </div>
      <Link
        to="/"
        className="bg-yellow font-semibold uppercase p-5 text-deep-blue hover:bg-[#701a75] hover:text-white transition duration-500"
      >
        return to home page
      </Link>
    </div>
  );
};
export default PageNotFound;
