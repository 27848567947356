import PhotoView from "./PhotoView";

const AllPhotos = () => {
  const categories = ["People", "Nature", "Urban"];
  return (
    <>
      {categories.map((category) => (
        <PhotoView key={category} category={category} />
      ))}
    </>
  );
};

export default AllPhotos;
