import { useState, useEffect } from "react";
import { storage } from "../../firebase";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { motion } from "framer-motion";
import Masonry from "react-masonry-css";
import useMediaQuery from "../../hooks/useMediaQuery";
import Loading from "../../components/Loading";

const PhotoView = ({ category }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (id) => {
    const index = imageUrls.findIndex((image) => image.id === id);
    setSelectedImage(imageUrls[index].url);
  };

  const handleNext = () => {
    setCurrentIndex(
      currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1
    );
    setSelectedImage(
      imageUrls[currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1]
        .url
    );
  };

  const handlePrevious = () => {
    setCurrentIndex(
      currentIndex === 0 ? imageUrls.length - 1 : currentIndex - 1
    );
    setSelectedImage(
      imageUrls[currentIndex === 0 ? imageUrls.length - 1 : currentIndex - 1]
        .url
    );
  };

  useEffect(() => {
    const imagesListRef = ref(storage, `${category}/`);
    listAll(imagesListRef).then((response) => {
      const promises = response.items.map((item) => {
        return getDownloadURL(item).then((url) => {
          const timestamp = parseInt(item.name.split("_")[0]);
          return { id: item.fullPath, url, timestamp };
        });
      });
      Promise.all(promises).then((imageData) => {
        const sortedData = imageData.sort((a, b) => b.timestamp - a.timestamp);
        setImageUrls(sortedData);
      });
    });
  }, []);

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  const isAboveSmallscreens = useMediaQuery("(min-width: 768px)");
  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex"
        columnClassName="my-masonry-grid_column "
      >
        {imageUrls.map((image, index) => {
          return (
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: index * 0.2, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
              className={`relative ${
                isAboveSmallscreens ? "cursor-pointer" : "cursor-auto"
              } p-2`}
              key={image.id}
              onClick={() => handleImageClick(image.id)}
            >
              <Loading />
              <img className="relative " alt={image.url} src={image.url} />
              {isAboveSmallscreens ? (
                <div className="absolute inset-0 bg-black opacity-0 hover:opacity-50 transition-opacity"></div>
              ) : null}
            </motion.div>
          );
        })}
      </Masonry>

      {isAboveSmallscreens ? (
        <>
          {selectedImage && (
            <div
              data-carousel="slide"
              className="fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center"
            >
              <div
                onClick={() => setSelectedImage(null)}
                className="fixed w-screen h-screen bg-gray-900 bg-opacity-75"
              ></div>
              <div className="relative ">
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  src={selectedImage}
                  alt={selectedImage}
                  className="object-cover max-h-screen max-w-screen p-10 "
                />

                <button
                  onClick={() => setSelectedImage(null)}
                  className="absolute top-10 right-10 m-4 text-white text-lg p-2 rounded-full hover:bg-gray-800 focus:outline-none focus:bg-gray-800"
                  aria-label="Close"
                >
                  <svg
                    className="h-6 w-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M14.3 5.71a1 1 0 00-1.42 0L10 8.59l-2.88-2.88a1 1 0 10-1.42 1.42L8.59 10l-2.88 2.88a1 1 0 001.42 1.42L10 11.41l2.88 2.88a1 1 0 001.42 0 1 1 0 000-1.42L11.41 10l2.89-2.88a1 1 0 000-1.41z" />
                  </svg>
                </button>
                <button
                  className="absolute top-1/2 left-0 transform -translate-y-1/2 w-12 h-12 text-white bg-gray-800 bg-opacity-50 rounded-full transition-opacity hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50"
                  onClick={handlePrevious}
                >
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M15 18l-6-6 6-6" />
                  </svg>
                  <span className="sr-only">Previous</span>
                </button>
                <button
                  className="absolute top-1/2 right-0 transform -translate-y-1/2 w-12 h-12 text-white bg-gray-800 bg-opacity-50 rounded-full transition-opacity hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50"
                  onClick={handleNext}
                >
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M9 6l6 6-6 6" />
                  </svg>
                  <span className="sr-only">Next</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default PhotoView;
