import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Awards = () => {
  const awardPhotos = [
    {
      title: "Time Square Rainy Night",
      img: "/assets/Time-Square-Rainy-Night.jpg",
      link: "https://1x.com/photo/2450722",
    },
    {
      title: "Yellow Cub",
      img: "/assets/c-image-3.jpg",
      link: "https://1x.com/photo/2444849",
    },
    {
      title: "DTLA",
      img: "/assets/DTLA.jpg",
      link: "https://500px.com/photo/1063645093/dtla-by-hamlet-hayrapetyan",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section
      id="awards"
      className="pt-24 w-5/6 mx-auto mt-10 md:flex md:items-center md:h-full"
    >
      <div className="w-full">
        <motion.div
          className="text-xl text-yellow text-center md:text-left"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.1, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          AWARDS
        </motion.div>

        <motion.div
          className="h-0.5 bg-gradient-rainbow my-4"
          whileInView="visible"
          variants={{
            hidden: { width: 0 },
            visible: { width: "100%" },
          }}
          viewport={{ once: true, amount: 0.5 }}
          initial="hidden"
          transition={{ duration: 0.5 }}
        />

        <motion.div
          className=" justify-between my-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <Slider {...settings}>
            {awardPhotos.map((item, index) => {
              return (
                <a
                  key={index}
                  className=" text-center my-5 relative"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={item.img}
                    className="max-w-full md:max-h-[500px] object-cover   h-96 w-full"
                  />
                  <div className="absolute inset-0 bg-black opacity-0 hover:opacity-80 transition-opacity">
                    <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xl font-bold">
                      {item.title}
                    </p>
                  </div>
                </a>
              );
            })}
          </Slider>
        </motion.div>
      </div>
    </section>
  );
};

export default Awards;
