import { useContext, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const LoginPage = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        dispatch({ type: "LOGIN", payload: user });
        navigate("/upload-photos");
      })
      .catch((error) => {
        setError(true);
      });
  };

  return (
    <form onSubmit={handleLogin}>
      <ul className="m-6">
        <li>
          <input
            type="email"
            placeholder="email"
            className=" mt-4  text-black"
            onChange={(e) => setEmail(e.target.value)}
          />
        </li>
        <li>
          <input
            type="password"
            placeholder="password"
            className=" mt-4  text-black"
            onChange={(e) => setPassword(e.target.value)}
          />
        </li>
        <li>
          <button type="submit" className=" mt-4">
            Login
          </button>
        </li>
        <li>{error && <span>Wrong email or password</span>}</li>
      </ul>
    </form>
  );
};

export default LoginPage;
