import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Photos = () => {
  const photoGallery = [
    {
      title: "URBAN",
      img: "../assets/c-image-5.jpg",
      link: "photo-gallery/urban",
    },
    {
      title: "PEOPLE",
      img: "../assets/01.jpg",
      link: "photo-gallery/people",
    },
    {
      title: "NATURE",
      img: "../assets/10.jpg",
      link: "photo-gallery/nature",
    },
  ];
  return (
    <section
      id="gallery"
      className="pt-24 w-5/6 mx-auto mt-10 md:flex md:items-center md:h-full "
    >
      <div className="w-full">
        <motion.div
          className="text-xl text-yellow text-center md:text-left"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.1, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          GALLERY
        </motion.div>
        <motion.div
          className="h-0.5 bg-gradient-rainbow my-4"
          whileInView="visible"
          variants={{
            hidden: { width: 0 },
            visible: { width: "100%" },
          }}
          viewport={{ once: true, amount: 0.5 }}
          initial="hidden"
          transition={{ duration: 0.5 }}
        />

        <div className="md:flex justify-between my-10">
          {photoGallery.map((items, index) => {
            return (
              <motion.div
                key={items.title}
                className=" relative"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: index * 0.2, duration: 0.5 }}
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <Link to={items.link}>
                  <img
                    src={items.img}
                    alt={items.title}
                    className="max-w-full md:max-h-[500px] object-cover h-full w-full"
                  />
                  <div className="absolute inset-0 bg-black opacity-0 hover:opacity-80 transition-opacity">
                    <p className="absolute left-1/2 text-center top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xl font-bold">
                      {items.title}
                    </p>
                  </div>
                </Link>
              </motion.div>
            );
          })}
        </div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="mx-auto justify-center  flex"
        ></motion.div>
      </div>
    </section>
  );
};
export default Photos;
