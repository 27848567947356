import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";

import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCqtJ0gbJLU7Zx21ilAOT5NXsFkHog2uUk",
  authDomain: "photos-portfolio-7d787.firebaseapp.com",
  projectId: "photos-portfolio-7d787",
  storageBucket: "photos-portfolio-7d787.appspot.com",
  messagingSenderId: "336658399778",
  appId: "1:336658399778:web:917a2a61e42b2d18aa407b",
  measurementId: "G-ZYQ4HS0PTR",
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);

export const db = getFirestore(app);
