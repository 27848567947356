import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const ImageCarousel = () => {
  const images = [
    "./assets/c-image-1.jpg",
    "./assets/c-image-3.jpg",
    "./assets/c-image-6.jpg",
    "./assets/c-image-7.jpg",
    "./assets/c-image-11.jpg",
    "./assets/c-image-12.jpg",
    "./assets/c-image-13.jpg",
    "./assets/c-image-14.jpg",
    "./assets/c-image-19.jpg",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        currentIndex === images.length - 1 ? 0 : currentIndex + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleNext = () => {
    setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
  };

  const handlePrevious = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  return (
    <section id="home" className="relative w-full h-screen  overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
        <AnimatePresence initial={false}>
          <motion.img
            key={currentIndex}
            src={images[currentIndex]}
            alt="carousel image"
            className="absolute top-0 left-0 w-full h-full object-cover "
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
          />
        </AnimatePresence>
        <AnimatePresence initial={false}>
          <motion.img
            key={currentIndex + 1}
            src={
              images[currentIndex === images.length - 1 ? 0 : currentIndex + 1]
            }
            alt="carousel item"
            className="absolute top-0 left-0 w-full h-full object-cover "
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          />
        </AnimatePresence>
      </div>
      <div
        className="absolute top-0 left-0 w-1/2 h-full "
        onClick={handlePrevious}
      ></div>
      <div
        className="absolute top-0 right-0 w-1/2 h-full "
        onClick={handleNext}
      ></div>
    </section>
  );
};

export default ImageCarousel;
