import ImageUpload from "./ImageUpload";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

const PhotosUpload = () => {
  const { dispatch } = useContext(AuthContext);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  return (
    <div>
      <ImageUpload category="Nature" />
      <ImageUpload category="People" />
      <ImageUpload category="Urban" />
      <button className=" bg-yellow text-black p-2" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default PhotosUpload;
