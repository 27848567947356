import { motion } from "framer-motion";

const About = () => {
  return (
    <section
      id="about"
      className="pt-24 w-5/6 mx-auto mt-10 md:flex md:items-center md:h-full"
    >
      <div className="w-full ">
        <motion.div
          className="text-xl text-yellow text-center md:text-left"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          ABOUT
        </motion.div>
        <motion.div
          className="h-0.5 bg-gradient-rainbow my-4"
          whileInView="visible"
          variants={{
            hidden: { width: 0 },
            visible: { width: "100%" },
          }}
          viewport={{ once: true, amount: 0.5 }}
          initial="hidden"
          transition={{ duration: 0.5 }}
        />

        <div className="md:flex md:items-center">
          <motion.div
            className="basis-2/3 md:mr-10 "
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <h5 className="xs:font-thin text-[18px] text-justify mb-10">
              I was born in Yerevan, Armenia in 1984 and currently reside in Los
              Angeles, where I continue to explore everyday moments and street
              life through my photography. My goal is to discover significance
              in the world through this art form. If I am fortunate enough to
              capture a fleeting moment and transform it into a work of art,
              then I consider it a successful day. Thank you for taking the time
              to visit my portfolio.
            </h5>
          </motion.div>

          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
            alt="profile"
            className=" z-10 w-full md:max-w-[500px] my-5 flex justify-center"
            src="assets/profile.jpg"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
