import { useState, useEffect } from "react";
import { db, storage } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";

const ImageUpload = ({ category }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const imagesCollectionRef = collection(db, `${category}/`);

  const uploadImage = async () => {
    if (!newImage) return;
    const timestamp = new Date().getTime();
    const storageRef = ref(
      storage,
      `${category}/${timestamp}_${newImage.name}`
    );
    await uploadBytes(storageRef, newImage);
    const imageUrl = await getDownloadURL(storageRef);
    const docRef = await addDoc(imagesCollectionRef, { imageUrl, timestamp });
    const newImageUrl = { imageUrl, id: docRef.id };
    setImageUrls([newImageUrl, ...imageUrls]);
  };

  const deleteImage = async (id, imageUrl) => {
    const storageRef = ref(storage, imageUrl);
    await deleteObject(storageRef);
    const imageDocRef = doc(db, `${category}/${id}`);
    await deleteDoc(imageDocRef);
    setImageUrls(imageUrls.filter((image) => image.id !== id));
  };

  useEffect(() => {
    const getImagesUrl = async () => {
      const data = await getDocs(imagesCollectionRef);
      const images = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const sortedImages = images.sort((a, b) => b.timestamp - a.timestamp);
      setImageUrls(sortedImages);
    };
    getImagesUrl();
  }, []);

  useEffect(() => {
    const imagesListRef = ref(storage, `${category}/`);
    listAll(imagesListRef).then((response) => {
      const promises = response.items.map((item) => {
        return getDownloadURL(item).then((url) => {
          const timestamp = parseInt(item.name.split("_")[0]);
          return { id: item.fullPath, imageUrl: url, timestamp };
        });
      });
      Promise.all(promises).then((imageData) => {
        const sortedData = imageData.sort((a, b) => b.timestamp - a.timestamp);
        setImageUrls(sortedData);
      });
    });
  }, [category]);

  return (
    <div className="w-5/6 mx-auto ">
      <div className="text-lg">{category}</div>
      <input
        type="file"
        onChange={(event) => {
          setNewImage(event.target.files[0]);
        }}
      ></input>
      <button className="bg-yellow p-2 text-black" onClick={uploadImage}>
        Upload Image
      </button>
      <div className="mt-4 columns-[120px]">
        {imageUrls.map((imageUrl) => {
          return (
            <div className="w-[100px] mr-4 text-center" key={imageUrl.id}>
              <img src={imageUrl.imageUrl} alt={imageUrl.id} />

              <button
                className="text-[0.8rem]"
                onClick={() => deleteImage(imageUrl.id, imageUrl.imageUrl)}
              >
                delete
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageUpload;
